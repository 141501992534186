import React from "react";
import "../css/Companies.css";
import Company from "./Company";
import NewCompany from "./NewCompany";
import EditCompany from "./EditCompany";
import axios from "axios";
import { useState, useEffect } from "react";

function Companies() {
  const [companies, setCompanies] = useState([]);
  const [addingMode, setAddingMode] = useState(false);
  const [editCompany, setEditCompany] = useState([]);
  const [editMode, setEditMode] = useState(0);
  const [deleteMode, setDeleteMode] = useState(0);

  useEffect(() => {
    axios
      .post("https://files.hrbynda.ro/api/preluare_societati.php")
      .then((response) => {
        setCompanies(response.data.societati);
      });
  }, [addingMode, editMode, deleteMode]);

  const handleAddClick = () => {
    setAddingMode((addingMode) => !addingMode);
  };

  const handleEditClick = (event, key) => {
    setEditMode(key);
    const currentCompany = companies.filter((company) => {
      return company.id === key;
    });
    setEditCompany(currentCompany[0]);
  };

  const handleDeleteClick = (event, key) => {
    setDeleteMode(key);
    axios
      .post("https://files.hrbynda.ro/api/stergere_societate.php", {
        id: key,
      })
      .then((response) => {
        alert(response.data.message);
        alert(response.data.response_code);
        setDeleteMode(0);
      });
  };

  return (
    <div className="companies-container">
      <button className="new-company-button" onClick={handleAddClick}>
        <span className="plus-symbol">&#43;</span> Add new company
      </button>
      <div className="companies-div">
        {companies.map((company) => (
          <Company
            key={company.id}
            name={company.denumire}
            onrc={company.onrc}
            caf={company.caf}
            anofm={company.anofm}
            org={company.organigrama}
            pcc={company.cazier}
            handleEditClick={(event) => handleEditClick(event, company.id)}
            handleDeleteClick={(event) => handleDeleteClick(event, company.id)}
          />
        ))}
      </div>
      {addingMode && <NewCompany closeAdding={handleAddClick} />}
      {editMode !== 0 && (
        <EditCompany
          closeEdit={(event) => handleEditClick(event, 0)}
          id={editCompany.id}
          name={editCompany.denumire}
          onrc={editCompany.onrc}
          caf={editCompany.caf}
          anofm={editCompany.anofm}
          org={editCompany.organigrama}
          pcc={editCompany.cazier}
        />
      )}
    </div>
  );
}

export default Companies;
