import Header from "./components/Header";
import Companies from "./components/Companies";
import Files from "./components/Files";
import { useState } from "react";

function App() {
  const [components, setComponents] = useState(0);

  function showCompanies() {
    setComponents(0);
  }

  function showFiles() {
    setComponents(1);
  }

  return (
    <div className="App">
      <Header
        buttonSelected={components}
        onClickCompanies={showCompanies}
        onClickFiles={showFiles}
      />
      {components == 0 ? <Companies /> : <Files />}
    </div>
  );
}

export default App;
