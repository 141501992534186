import React from "react";
import "../css/Companies.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faPenToSquare } from "@fortawesome/free-regular-svg-icons";

function Company(props) {
  // Functie pentru formatarea datei
  function formatDate(date) {
    var data = new Date(date);
    return data.toLocaleDateString("en-GB", {
      day: "numeric",
      month: "short",
      year: "2-digit",
    });
  }

  // Functie pentru determinarea datei de expirare
  function expirationDate(date, valability) {
    var data = new Date(date);
    data.setMonth(data.getMonth() + valability);
    return formatDate(data);
  }

  // Functie pentru verificarea valabilitatii
  function checkValability(date) {
    var currentDate = new Date();
    var data = new Date(date);
    var date_difference = data.getTime() - currentDate.getTime();
    console.log(date_difference);
    if (date_difference >= 550136393) {
      return "company-details-label";
    } else if (date_difference < 550136393 && date_difference > 0) {
      return "company-details-label-warning";
    } else {
      return "company-details-label-expired";
    }
  }

  return (
    <div className="company-div">
      <span className="company-title">{props.name}</span>
      <span className="company-details">
        <span className={checkValability(expirationDate(props.onrc, 1))}>
          ONRC
        </span>
        {formatDate(props.onrc)} - {expirationDate(props.onrc, 1)}
      </span>
      <span className="company-details">
        <span className={checkValability(expirationDate(props.caf, 1))}>
          CAF
        </span>
        {formatDate(props.caf)} - {expirationDate(props.caf, 1)}
      </span>
      <span className="company-details">
        <span className={checkValability(expirationDate(props.anofm, 2))}>
          ANOFM
        </span>
        {formatDate(props.anofm)} - {expirationDate(props.anofm, 2)}
      </span>
      <span className="company-details">
        <span className={checkValability(expirationDate(props.org, 12))}>
          Org. Chart
        </span>
        {formatDate(props.org)} - {expirationDate(props.org, 12)}
      </span>
      <span className="company-details">
        <span className={checkValability(expirationDate(props.pcc, 6))}>
          Police Report
        </span>
        {formatDate(props.pcc)} - {expirationDate(props.pcc, 6)}
      </span>
      <div className="action-buttons-div">
        <button className="edit-button" onClick={props.handleEditClick}>
          <FontAwesomeIcon icon={faPenToSquare} />
          Edit
        </button>
        <button className="delete-button" onClick={props.handleDeleteClick}>
          <FontAwesomeIcon icon={faTrashAlt} />
          Delete
        </button>
      </div>
    </div>
  );
}

export default Company;
