import React, { useState } from "react";
import axios from "axios";
import "../css/NewCompany.css";

function EditCompany(props) {
  // Formatare data pentru input (yyyy-mm-dd)
  function createDate(string_date) {
    var data = new Date(string_date);
    var year = data.toLocaleString("default", { year: "numeric" });
    var month = data.toLocaleString("default", { month: "2-digit" });
    var day = data.toLocaleString("default", { day: "2-digit" });
    return year + "-" + month + "-" + day;
  }
  const [name, setName] = useState(props.name);
  const [onrc, setOnrc] = useState(createDate(props.onrc));
  const [caf, setCaf] = useState(createDate(props.caf));
  const [anofm, setAnofm] = useState(createDate(props.anofm));
  const [org, setOrg] = useState(createDate(props.org));
  const [pcc, setPcc] = useState(createDate(props.pcc));

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      name != "" &&
      onrc != "" &&
      caf != "" &&
      anofm != "" &&
      org != "" &&
      pcc != ""
    ) {
      axios
        .post("https://files.hrbynda.ro/api/editare_societate.php", {
          id: props.id,
          denumire: name,
          onrc: onrc,
          caf: caf,
          anofm: anofm,
          organigrama: org,
          cazier: pcc,
        })
        .then((response) => {
          alert(response.data.message);
          props.closeEdit();
        });
    } else {
      alert("Nu e ok");
    }
  };
  return (
    <div className="new-company-container">
      <div className="new-company-div">
        <span className="new-company-title">Edit company</span>
        <form className="form-new-company" onSubmit={handleSubmit}>
          <label htmlFor="name" className="label-new-company">
            Name
          </label>
          <input
            id="name"
            value={name}
            type="text"
            className="input-new-company"
            onChange={(e) => setName(e.target.value)}
          />
          <label htmlFor="onrc" className="label-new-company">
            ONRC
          </label>
          <input
            id="onrc"
            value={onrc}
            type="date"
            className="input-new-company"
            onChange={(e) => setOnrc(e.target.value)}
          />
          <label htmlFor="caf" className="label-new-company">
            CAF
          </label>
          <input
            id="caf"
            value={caf}
            type="date"
            className="input-new-company"
            onChange={(e) => setCaf(e.target.value)}
          />
          <label htmlFor="anofm" className="label-new-company">
            ANOFM
          </label>
          <input
            id="anofm"
            value={anofm}
            type="date"
            className="input-new-company"
            onChange={(e) => setAnofm(e.target.value)}
          />
          <label htmlFor="org" className="label-new-company">
            Org. Chart
          </label>
          <input
            id="org"
            value={org}
            type="date"
            className="input-new-company"
            onChange={(e) => setOrg(e.target.value)}
          />
          <label htmlFor="pcc" className="label-new-company">
            Police Report
          </label>
          <input
            id="pcc"
            value={pcc}
            type="date"
            className="input-new-company"
            onChange={(e) => setPcc(e.target.value)}
          />
          <div className="buttons-div-new-company">
            <button className="discard-new-company" onClick={props.closeEdit}>
              Discard
            </button>
            <button className="save-new-company">Save</button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default EditCompany;
