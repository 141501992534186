import React from "react";
import logo from "../logo.png";
import "../css/Header.css";

function Header(props) {
  return (
    <header className="header">
      <img src={logo} className="logo" alt="logo" />
      <p className="title">Files Management System</p>
      <button
        className={
          props.buttonSelected == 0 ? "nav-button-selected" : "nav-button"
        }
        onClick={props.onClickCompanies}
      >
        Companies
      </button>
      <button
        className={
          props.buttonSelected == 1 ? "nav-button-selected" : "nav-button"
        }
        onClick={props.onClickFiles}
      >
        Files
      </button>
    </header>
  );
}

export default Header;
